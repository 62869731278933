import { TooltipWrapper } from '@va/ui/tooltips';
import { PropsWithChildren } from 'react';
import { AvailableFiltersDropdown } from '../available-filters-dropdown';

export const AddFilterTooltip = ({
  children,
  isOpen,
  openChange,
}: PropsWithChildren<{ isOpen: boolean; openChange: (open: boolean) => void }>) => {
  return (
    <TooltipWrapper
      useDefaultStyle={false}
      placement='bottom'
      trigger='click'
      disabled={!isOpen}
      arrow={false}
      interactive
      open={isOpen}
      onOpenChange={openChange}
      tooltipClassNames='shadow-overlay rounded-18px xs:ml-18px xs:!min-w-[calc(100vw-42px)]'
      content={
        <AvailableFiltersDropdown
          closeDropdown={() => {
            openChange(false);
          }}
        />
      }
      zIndex={'var(--zIndex-filters-dropdown)'}
    >
      {children}
    </TooltipWrapper>
  );
};
