import { TEST_IDS } from '@va/constants';
import {
  BackIconRounded,
  BarGraphIcon,
  BorderIcon,
  FallbackIconCircle,
  GridIcon,
  HorizontalBarGraphIcon,
  LineDiagramIcon,
  LocationIcon,
  PieChartIcon,
  RadarDiagramIcon,
  RightArrowIcon,
  SplitTableLineDiagram,
  SplitTablePieDiagram,
  SplitTableRecording,
  TableListIcon,
} from '@va/icons';
import { useTranslate } from '@va/localization';
import {
  DataViewOption,
  DataViewProps,
  NestedVisualizationOption,
  isNestedVisualizationOption,
} from '@va/types/report-block';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { Checkmark, DropdownArrow } from '@va/util/components';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from '../button';
import { HorizontalSeparator } from '../separators';
import { Paragraph, fontWeights } from '../typography';

export const DataViewButton = ({ selectedView, viewOptions, onViewChange, size = 'medium' }: DataViewProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const isHovering = useIsHovering(btnRef);
  const isDropdownAvailable = useMemo(() => {
    if (!viewOptions || viewOptions?.length <= 1 || !onViewChange) {
      return false;
    }

    return true;
  }, [onViewChange, viewOptions]);

  const ViewIcon = useMemo(() => {
    return getViewIcon(selectedView);
  }, [selectedView]);

  const translate = useTranslate();

  if (!isDropdownAvailable) {
    return (
      <TooltipWrapper
        trigger='click'
        content={translate('all.dataVisualization.notAvailable', {
          value: translate(`all.dataViewOptions.${selectedView}`),
        })}
      >
        <div>
          <button
            data-testid={TEST_IDS.generic.visualizationOptions.toggleBtn}
            ref={btnRef}
            className={classNames(
              'shrink-0 cursor-pointer bg-white-snow hover:bg-primary rounded-full flex items-center justify-center',
              {
                'w-12 h-12 sm:h-9 sm:w-9 sm:rounded-9px': size === 'medium',
                'w-42px h-42px': size === 'small',
              },
            )}
          >
            <ViewIcon color={isHovering ? '#FFFFFF' : window.PRIMARY_COLOR} />
          </button>
        </div>
      </TooltipWrapper>
    );
  }

  return (
    <TooltipWrapper content={translate('all.dataVisualization.showMoreOptions')}>
      <TooltipWrapper
        useDefaultStyle={false}
        arrow={false}
        interactive
        trigger='click'
        tooltipClassNames='shadow-md rounded-15'
        content={
          <ViewOptionsDropdown viewOptions={viewOptions} selectedView={selectedView} onViewChange={onViewChange} />
        }
      >
        <div>
          <button
            className={classNames(
              'relative rounded-full flex items-center justify-center bg-white-snow hover:bg-primary',
              {
                'w-12 h-12 sm:h-9 sm:w-9 sm:rounded-9px': size === 'medium',
                'w-42px h-42px': size === 'small',
              },
            )}
            ref={btnRef}
            data-testid={TEST_IDS.generic.visualizationOptions.toggleBtn}
          >
            <ViewIcon className='sm:w-15px sm:h-15px' color={isHovering ? '#FFFFFF' : window.PRIMARY_COLOR} />
            <div className='absolute bottom-1 sm:bottom-0.5 left-1/2 -translate-x-1/2'>
              <ArrowIcon
                iconClassName={classNames({
                  'w-1.5': size === 'small',
                  'w-2.5 sm:w-1.5': size === 'medium',
                })}
                color={isHovering ? '#FFFFFF' : '#969696'}
              />
            </div>
          </button>
        </div>
      </TooltipWrapper>
    </TooltipWrapper>
  );
};

const ArrowIcon = ({ color = '#969696', iconClassName }: { color?: string; iconClassName?: string }) => {
  const { context } = useTooltipContext();
  return <DropdownArrow iconClassName={iconClassName} color={color} open={context.open} />;
};

const ViewOptionsDropdown = ({ viewOptions = [], selectedView, onViewChange }: DataViewProps) => {
  const translate = useTranslate();
  const [nestedOption, setNestedOption] = useState<NestedVisualizationOption | undefined>(undefined);

  const { context } = useTooltipContext();

  const closeTooltip = useCallback(() => {
    context.onOpenChange(false);
  }, [context]);

  return (
    <div
      data-testid={TEST_IDS.generic.visualizationOptions.dropdownContainer}
      className='bg-white p-6px rounded-15 min-w-300px'
    >
      {nestedOption && (
        <div className='flex gap-3 items-center py-4 px-3'>
          <Button
            color='tertiary'
            onClick={() => setNestedOption(undefined)}
            icon={(_, color) => <BackIconRounded color={color} />}
          />
          <Paragraph colorClassName='text-gray-devilSolid' weight={fontWeights.medium}>
            {translate(`all.dataViewOptionsSection.${nestedOption.view}.title`)}
          </Paragraph>
        </div>
      )}
      {!nestedOption && (
        <Paragraph className='py-4 px-18px' colorClassName='text-gray-devilSolid' weight={fontWeights.medium}>
          {translate('all.dataViewOptionsSection.title')}
        </Paragraph>
      )}
      <HorizontalSeparator className='-mx-1' />
      <ul>
        {nestedOption &&
          nestedOption.options.map((option) => {
            return (
              <ViewListItem
                key={option}
                onClick={() => {
                  onViewChange?.(option);
                  closeTooltip();
                }}
                view={option}
                isSelected={option === selectedView}
              />
            );
          })}

        {!nestedOption &&
          viewOptions.map((option) => {
            if (isNestedVisualizationOption(option)) {
              return (
                <ViewListItem
                  key={option.view}
                  view={option.view}
                  onClick={() => {
                    setNestedOption(option);
                  }}
                  isSelected={false}
                  hasSubOptions={true}
                />
              );
            } else {
              return (
                <ViewListItem
                  key={option}
                  onClick={() => {
                    onViewChange?.(option);
                    closeTooltip();
                  }}
                  view={option}
                  isSelected={option === selectedView}
                />
              );
            }
          })}
      </ul>
      <Button
        data-testid={TEST_IDS.generic.buttons.close}
        onClick={closeTooltip}
        className='w-full'
        color='quinary'
        text={translate('button.close')}
      />
    </div>
  );
};

export const ViewListItem = ({
  isSelected,
  onClick,
  view,
  hasSubOptions = false,
}: {
  isSelected: boolean;
  onClick: (value: any) => void;
  view: DataViewOption;
  hasSubOptions?: boolean;
}) => {
  const Icon = useMemo(() => getViewIcon(view), [view]);
  const translate = useTranslate();
  return (
    <li
      data-testid={TEST_IDS.helpers.createListItemId(`visualization-option-${view}`)}
      onClick={onClick}
      className='flex items-center gap-3 p-3 rounded-12 hover:bg-white-snow active:bg-gray-concrete cursor-pointer'
    >
      {<Icon color='#696969' />}
      <Paragraph weight={fontWeights.medium}>{translate(`all.dataViewOptions.${view}`)}</Paragraph>
      <div className='ml-auto'>
        {hasSubOptions ? <RightArrowIcon color='#969696' className='h-6 w-6' /> : <Checkmark selected={isSelected} />}
      </div>
    </li>
  );
};

export function getViewIcon(view: DataViewOption) {
  switch (view) {
    case DataViewOption.lineDiagram:
    case DataViewOption.stackedLineDiagram:
    case DataViewOption.steppedLineDiagram:
      return LineDiagramIcon;
    case DataViewOption.radarDiagram:
      return RadarDiagramIcon;
    case DataViewOption.table:
      return TableListIcon;
    case DataViewOption.barChart:
      return BarGraphIcon;
    case DataViewOption.horizontalBarChart:
      return HorizontalBarGraphIcon;
    case DataViewOption.pieChart:
      return PieChartIcon;
    case DataViewOption.kpi:
      return BorderIcon;
    case DataViewOption.map:
      return LocationIcon;
    case DataViewOption.card:
      return GridIcon;
    case DataViewOption.splitTableLineDiagram:
      return SplitTableLineDiagram;
    case DataViewOption.splitTablePieDiagram:
      return SplitTablePieDiagram;
    case DataViewOption.splitTableRecording:
      return SplitTableRecording;
  }
  return FallbackIconCircle;
}
