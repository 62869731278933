import { useTranslate } from '@va/localization';
import { DefaultFilterOptionType } from '@va/types/filters';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { FilterBlock } from '../../FilterBlock';
import { FilterSelectDropdown } from '../../FilterSelectDropdown';
import { useFiltersContext } from '../../filters-context';
import { MultiSelectFilterInputProps } from './MultiSelectFilterInput';

type MultiSelectFilterInputAppliedProps = {
  disableEditing?: boolean;
  viewOnly?: boolean;
  isGrouped?: boolean;
  size?: 'small' | 'medium' | 'large';
} & MultiSelectFilterInputProps;

export const MultiSelectFilterInputApplied: React.FC<MultiSelectFilterInputAppliedProps> = ({
  useOptions,
  filter,
  disableEditing,
  viewOnly,
  isGrouped,
  size = 'large',
}) => {
  const { id, operator, values, operatorOptions, label, inputProps, logicalOperator } = filter;

  const translate = useTranslate();

  const { updateAppliedFilter } = useFiltersContext();

  const { data = [] } = useOptions();
  const [options, setOptions] = useState<DefaultFilterOptionType[]>(data);

  const mappedOptions = useMemo(() => {
    const optionsWithLogicalOperator: DefaultFilterOptionType[] = [];

    const optionsObject = data.reduce((acc, option) => {
      acc[option.value] = option;
      optionsWithLogicalOperator.push({ ...option, logicalOperator });
      return acc;
    }, {} as Record<string | number, DefaultFilterOptionType>);

    setOptions(optionsWithLogicalOperator);
    return optionsObject;
  }, [data, logicalOperator]);

  const selectedOptions = useMemo(() => {
    return values.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {} as Record<string | number, boolean>);
  }, [values]);

  const optionToDisplay = useMemo(() => {
    const value = values[0];
    if (value === inputProps?.allOption?.value) {
      return inputProps.allOption;
    }
    if (!mappedOptions[value]) return undefined;
    return mappedOptions[value];
  }, [inputProps?.allOption, mappedOptions, values]);

  return (
    <FilterBlock
      filterId={id}
      fieldName={label}
      operator={operator}
      operatorOptions={operatorOptions}
      hideDeleteButton={viewOnly}
      disabled={disableEditing}
      size={size}
      isGrouped={isGrouped}
      input={
        <FilterSelectDropdown
          allOption={inputProps?.allOption}
          closeOnClick={false}
          triggerClassName={classNames('input-wrapper', {})}
          options={options}
          selectedOptions={selectedOptions}
          disabled={disableEditing}
          onChange={(option) => {
            if (option.allOption) {
              updateAppliedFilter(id, { values: [option.value] });
              return;
            }

            const isSelected = selectedOptions[option.value];
            const filteredValues = values.filter((val) => val !== inputProps?.allOption?.value);

            if (isSelected) {
              const newValues = filteredValues.filter((value: any) => value !== option.value);
              updateAppliedFilter(id, { values: newValues });
            } else {
              updateAppliedFilter(id, { values: [...filteredValues, option.value] });
            }
          }}
        >
          <div className='flex items-center gap-2'>
            {optionToDisplay?.icon}
            <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
              {optionToDisplay?.label || '-'}
            </Paragraph>
            {values.length > 1 && (
              <Paragraph colorClassName='text-gray-devil' size={paragraphSizes.tiny} weight={fontWeights.medium}>
                {translate('all.filters.otherItemsCount', { count: values.length - 1 })}
              </Paragraph>
            )}
          </div>
        </FilterSelectDropdown>
      }
    />
  );
};
